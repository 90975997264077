<template>
	<div class="culture">
		<div class="title">{{ data.title }}</div>
		<!-- <div class="menu">
			<div>作者：{{ data.author }}</div>
			<div style="margin-left:40px">文章描述：{{ data.description }}</div>
		</div> -->
		<div class="content" v-html="data.content"></div>
		<div style="width: 100%; height: 20px"></div>
	</div>
</template>
<script>
import utils from '@/common/utils';
export default {
	data() {
		return {
			data: {}
		};
	},
	mounted() {
		this.getList(this.$route.query.id);
	},
	methods: {
		getList(id) {
			utils.showSpin();
			this.http.get(`/api/common/attract`, { articleId: id }).then(ret => {
				console.log(ret.data.records[0]);
				this.data = ret.data.records[0];
				utils.closeSpin();
			});
		}
	}
};
</script>

<style scoped>
.culture {
	width: 96%;
	margin: 0 auto;
}
.title {
	font-size: 35px;
	text-align: center;
	line-height: 70px;
	border-bottom: 1px solid #ccc;
}
.menu {
	display: flex;
	color: #ccc;
}
.content {
	text-indent: 2em;
	margin-top: 10px;
}
</style>
